export const ACTION_PRODUCTS_ERROR = "ACTION_PRODUCTS_ERROR";
export const ACTION_PRODUCTS_START = "ACTION_PRODUCTS_START";

export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const CURRENCY_CHANGE = "CURRENCY_CHANGE";

