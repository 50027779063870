import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getProduct } from "../../../redux/actions/productos/productAction";

import { Box, Modal } from "@mui/material";
import { NativeSelect } from '@mantine/core';

import { v4 as uuidv4 } from "uuid";

import iconWorld from "../../../assets/svg/information-icons/world-icon.svg";
import iconWhiteCross from "../../../assets/svg/functionalities-icons/cross_white.svg";
import iconGrayArrow from "../../../assets/svg/functionalities-icons/flecha-gris-imagen-derecha.svg";
import iconWhiteArrow from "../../../assets/svg/functionalities-icons/flecha-blanca-der-imagen-zoom.svg";
import noImage from "../../../assets/svg/system-images/producto-sin-imagen.svg";
import expandArrow from "../../../assets/svg/menu-icons/flecha-triangular.svg";

import MenuItems from "../../navbar/MenuItems";
import { CurrencyCard } from "../../recommendations/currencyCard/CurrencyCard";

import "./selectedProduct.css";
import { getCurrency } from "../../../redux/actions/utilities/currencyAction";

const initialProducto = {
	productoDesactualizado: "",
	productoCategoria: "",
	productoImagen: "",
	ordenarTexto: "",
};

const SelectedProduct = () => {
	const dispatch = useDispatch();
	const [params] = useSearchParams();

	const product = useSelector((state) => state.product.product);
	const currency = useSelector((state) => state.products.currency);
	const currencyData = useSelector((state) => state.currency.data);
	const userRol = useSelector((state) => state.auth.user.role?.access);

	const [productId] = useState(params.get("id") || "");
	const [openModal, setOpenModal] = useState(false);
	const [openZoomModal, setOpenZoomModal] = useState(false);
	const [imageArray, setImageArray] = useState([]);
	const [zoomOpening, setZoomOpening] = useState(false);
	const [imagenActual, setImagenActual] = useState(0);
	const [detalleProducto, setDetalleProducto] = useState(initialProducto);

	const [seeMore, setSeeMore] = useState(true)

	const containerRef = useRef(null);
	const imageRef = useRef(null);
	const lensRef = useRef(null);
	const resultRef = useRef(null);

	const containerRect = containerRef.current?.getBoundingClientRect();
	containerRef.current?.addEventListener("mousemove", zoomImage);
	const imageRect = imageRef.current?.getBoundingClientRect();
	const lensRect = lensRef.current?.getBoundingClientRect();
	const resultRect = resultRef.current?.getBoundingClientRect();

	useEffect(() => {
		if (productId) dispatch(getProduct(productId, currency));
		dispatch(getCurrency());

		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		let objeto = initialProducto;
		if (product) {
			objeto.productoDesactualizado = Date.parse(new Date()) - Date.parse(product.timestamp) - 24 * 60 * 60 * 1000;
			objeto.productoImagen = product.info.images;
			objeto.ordenarTexto = product.info.description.toUpperCase().split("\n" || "    ");
			setDetalleProducto(objeto);
		}

		if (product && Array.isArray(product.info.images)) {
			let array = [];
			if (product.info.images.length > 1) {
				product.info.images.forEach((element, index) => {
					if (index <= 5) {
						array.push(
							<li
								className="selectedProduct-images-li"
								key={index}
								onClick={() => {
									handleImageChange(index);
								}}
								style={detalleProducto.productoImagen[imagenActual] === element ? { border: "2px solid var(--color-gray-blue)" } : {}}
							>
								<img src={element} alt={`img${index}`} />
							</li>
						);
					}
				});
				setImageArray(array);
			} else {
				setImageArray([]);
			}
		}
		document.title = `Scope | ${product ? product.info.model.toString().toUpperCase() : "Producto"}`

		if (!product) if (productId) dispatch(getProduct(productId, currency));
	}, [product, imagenActual]);

	const handleCurrencyChange = (value) => {
		dispatch(getProduct(productId, value));
	}

	const handleImageClick = () => {
		if (detalleProducto.productoImagen.length > 0)
			setOpenZoomModal(!openZoomModal);
	};

	const handleImageChange = (index) => {
		if (index === -1) {
			setImagenActual(detalleProducto.productoImagen.length - 1);
		} else if (index === detalleProducto.productoImagen.length) {
			setImagenActual(0);
		} else {
			setImagenActual(index);
		}
	};

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1))
	};

	const formatNumber = (valor) => {
		return new Intl.NumberFormat("de-DE").format((valor * 1))
	};

	if (containerRef.current) {
		resultRef.current.style.backgroundImage = `url(${detalleProducto.productoImagen[imagenActual]})`;
		containerRef.current.addEventListener("mousemove", zoomImage);
	}

	function zoomImage(e) {
		if (detalleProducto.productoImagen.length > 0) setZoomOpening(true);
		const { x, y } = getMousePos(e);

		lensRef.current.style.left = x + "px";
		lensRef.current.style.top = y + "px";

		let fx = resultRect.width / lensRect.width;
		let fy = resultRect.height / lensRect.height;

		resultRef.current.style.backgroundSize = `${imageRect.width * fx}px ${imageRect.height * fy}px`;
		resultRef.current.style.backgroundPosition = `-${x * fx}px -${y * fy}px`;
	}

	function getMousePos(e) {
		let x = e.clientX - containerRect.left - lensRect.width / 2;
		let y = e.clientY - containerRect.top - lensRect.height / 2;

		let maxX = containerRect.width - lensRect.width;
		let maxY = containerRect.height - lensRect.height;

		if (x <= 0) {
			x = 0;
		} else if (x >= maxX) {
			x = maxX;
		}
		if (y <= 0) {
			y = 0;
		} else if (y >= maxY) {
			y = maxY;
		}
		return { x, y };
	}

	return (
		<div className="selectedProduct-container">
			<MenuItems />
			{product && detalleProducto.ordenarTexto && (
				<>
					{product.info.productIndex.subcategories?.indices ? <div className={`selectedProduct-categoryLink`}>
						{product.info.productIndex._id &&
							<Link to={"/" + product.info.productIndex.name.replace(/ /g, "_") + '-' + product.info.productIndex.subcategories.name.replace(/ /g, "_") + '-' + product.info.productIndex.subcategories.indices.name.replace(/ /g, "_")} key={uuidv4()}>
								<span>Ir al listado</span>
							</Link>
						}
					</div> : <div className={`selectedProduct-no-categoryLink`}></div>}
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div className="selectedProduct-complete-info">
							<div className="selectedProduct-info">
								<div>
									<div className="selectedProduct-image-container">
										<section>
										</section>
										<img
											alt="iconGrayArrow"
											src={iconGrayArrow}
											style={detalleProducto.productoImagen.length > 1 ? { transform: "rotate(180deg)" } : { display: "none" }}
											onClick={() => handleImageChange(imagenActual - 1)}
										/>
										<div
											className="selectedProduct-zoom-container"
											ref={containerRef}
											onMouseLeave={() => setZoomOpening(false)}
											onClick={handleImageClick}
										>
											<img
												onMouseEnter={() => { if (detalleProducto.productoImagen.length > 0) setZoomOpening(true) }}
												ref={imageRef}
												className="selectedProduct-image"
												src={detalleProducto.productoImagen[0] ? detalleProducto.productoImagen[imagenActual] : noImage}
												alt="noPicture"
											/>
											<div ref={lensRef} className={`${zoomOpening && "selectedProduct-zoom-lens"}`}></div>
										</div>
										<img
											style={detalleProducto.productoImagen.length > 1 ? {} : { display: "none" }}
											alt="iconGrayArrow"
											src={iconGrayArrow}
											onClick={() => handleImageChange(imagenActual + 1)}
										/>
									</div>
									<ul className="selectedProduct-image-ul">{imageArray}</ul>
								</div>
								<div style={{ width: "100%" }}>
									<div ref={resultRef} className={zoomOpening ? "selectedProduct-zoom-result" : ""}></div>
									<div className="selectedProduct-mainInfo-container" style={detalleProducto.productoDesactualizado > 0 ? { border: "solid 2px var(--color-red)" } : {}}>
										{detalleProducto.productoDesactualizado > 0 ?
											<h5 className="selectedProduct-outdated">
												Producto desactualizado. Ultima actualización {product.timestamp.split("T")[0]}
											</h5>
											: <h5 className="selectedProduct-outdated" style={{ color: "var(--color-grey)" }}>
												Extracción de articulo: {product.timestamp.split("T")[1].split(':').slice(0, 2).join(':')}hs
											</h5>}
										<h4 className="selectedProduct-title">{product.info.brand.toString().toUpperCase()} - {product.info.model.toString().toUpperCase()}</h4>
										<h5 className="selectedProduct-subtitle">{product.info.version.toString().toUpperCase()}</h5>
										<div className="selectedProduct-prices-container">
											<span style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: "0"
											}}>
												<div>
													<h4>Precio</h4>
													<span>
														{currency}{" "}
														{formatearNumero(product.normalizedPrice)}
													</span>
												</div>

												{currencyData && <NativeSelect
													size="xs"
													onChange={(e) => { handleCurrencyChange(e.target.value) }}
													value={currency}
												>
													<option value={currencyData.name} key={currencyData.name}>{currencyData.name}</option>
													{Object.keys(currencyData.convertTo).map((item) => {
														return (<option value={item} key={item}>{item}</option>);
													})}
												</NativeSelect>}
											</span>
										</div>
									</div>
									<div className="selectedProduct-info-container">
										<span>
											<h4>Año</h4>
											<span>{product.info.year}</span>
										</span>
										{userRol !== "user" && <hr />}
										<span>
											<h4>Kilometraje</h4>
											<span>{formatNumber(product.info.kms)} kms</span>
										</span>
										{userRol !== "user" && <hr />}
										<span>
											<h4>Diferencia interna</h4>
											<span>--- %</span>
										</span>
									</div>
									<div className="selectedProduct-info-container">
										<span>
											<h4>Empresa</h4>
											<span>{product.company.name}</span>
										</span>
										{userRol !== "user" && <hr />}
										<span>
											<h4>Marca</h4>
											<span>{product.info.brand || "---"}</span>
										</span>
									</div>
									<a
										className="selectedProduct-link-container"
										href={product.info.link}
										target="_blank"
										rel="noreferrer"
									>
										<img src={iconWorld} alt="icono-mundo" />
										<span className="selectedProduct-link-title">Ir a la página</span>
									</a>
								</div>
							</div>
							{product.info.description.length > 3 && (
								<div className="selectedProduct-description-container">
									<h4 className="selectedProduct-description-title">Descripción:</h4>
									<div className="selectedProduct-description-info" >
										{detalleProducto.ordenarTexto.map((producto, index) => {
											return <p key={index} style={seeMore ? { overflow: 'hidden' } : { overflow: 'visible', maxHeight: 'none' }}>{producto}</p>;
										})}
										<div onClick={() => setSeeMore(!seeMore)}>
											<h6>Ver {seeMore ? 'más' : 'menos'}</h6>
											<img src={expandArrow} alt="expand-arrow" style={seeMore ? { rotate: '90deg' } : { rotate: '270deg' }} />
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="selectedProduct-dollar-container">
							<CurrencyCard />
						</div>
					</div>
				</>
			)}
			<div onClick={() => setOpenModal(!openModal)} className="producto-icono-recomendaciones">$</div>
			<Modal open={openModal} key={uuidv4()}>
				<Box>
					<div className="selectedProduct-modal-container">
						<div className="producto-recomendaciones-seccion-iconos">
							<img src={iconWhiteCross} onClick={() => setOpenModal(!openModal)} alt="iconos-cruz" />
						</div>
						<div className="producto-recomendaciones-seccion-recomendaciones">
							{currencyData && <CurrencyCard />}
						</div>
					</div>
				</Box>
			</Modal>
			<Modal open={openZoomModal} key={uuidv4()}>
				<Box>
					<div className="selectedProduct-modal-container">
						<div className="selectedProduct-modal-icons">
							<div>
								{imagenActual + 1}/{detalleProducto.productoImagen.length}
							</div>
							<img src={iconWhiteCross} onClick={handleImageClick} alt="iconos-cruz" />
						</div>
						<div className="selectedProduct-modal-image-container">
							{detalleProducto.productoImagen.length > 1 && <img
								alt="iconWhiteArrow"
								src={iconWhiteArrow}
								style={{ transform: "rotate(180deg)" }}
								onClick={() => handleImageChange(imagenActual - 1)}
							/>}
							<img
								alt="iconos-productoImagen"
								src={detalleProducto.productoImagen[imagenActual]}
								style={{ maxWidth: "90%", maxHeight: "90vh", margin: "auto" }}
							/>
							{detalleProducto.productoImagen.length > 1 && <img alt="iconWhiteArrow" src={iconWhiteArrow} onClick={() => handleImageChange(imagenActual + 1)} />}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default SelectedProduct;
