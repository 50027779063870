import {
	ACTION_PRODUCTS_ERROR,
	ACTION_PRODUCTS_START,
	GET_BRANDS_SUCCESS,
	GET_COMPANIES_SUCCESS,
	GET_MODELS_SUCCESS,
	GET_PRODUCTS_SUCCESS,
	GET_FILTERS_SUCCESS,
} from "../../types/index";

import clienteAxios from "../../../Axios";

import universalCookie from "universal-cookie";

const cookie = new universalCookie();

export function getProducts(indexID, { company, brand, model, page, orderByField, orderByValue, minYear, maxYear, minKms, maxKms, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice, currency }) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const invalidCharacters = new RegExp("[{}()\\[\\]<>]");
			let indicesID = "";
			indexID.forEach(index => {
				indicesID += `${index},`
			}
			);
			if (!invalidCharacters.test(textSearch)) {
				const response = await clienteAxios({
					method: "GET",
					url: `
						products?indexID=${indicesID.slice(0, -1)}
						&filterSearch=${filterSearch.join(" ")}
						&brand=${brand}
						&company=${company}
						&currency=${currency}
						&hasPrice=${hasPrice}
						&isUpdated=${isUpdated}
						&maxKms=${maxKms}
						&maxPrice=${maxPrice}
						&maxYear=${maxYear}
						&minKms=${minKms}
						&minPrice=${minPrice}
						&minYear=${minYear}
						&model=${model}
						&orderByField=${orderByField}
						&orderByValue=${orderByValue}
						&page=${page}
						&textSearch=${textSearch}
						`,
					headers: {
						"x-acces-token": cookie.get("token"),
					},
				});
				dispatch(getProductsSuccess({ resp: response.data.response, brand, model, orderByField, orderByValue, company, minYear, maxYear, minKms, maxKms, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice, currency, page }));
			} else {
				throw new Error('Busqueda con caracteres inválidos');
			}
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function getProductsFilters(index) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const respuesta = await clienteAxios.get(`filter?productType=${index}`);
			dispatch(getFiltersSuccess(respuesta.data.response)); // TODO -< check error code para ver si exito o succes, no???
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function getProductsBrands(indexID, company) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {

			const respuesta = await clienteAxios({
				method: "GET",
				url: `productsBrands?indexID=${indexID}&company=${company}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			})
			dispatch(getBrandsSuccess(respuesta.data.response));
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function getProductsCompanies(indexID, brand) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: `productsCompanies?indexID=${indexID}&brand=${brand}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			})
			dispatch(getCompaniesSuccess(respuesta.data.response));
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function getProductsModels(indexID, brand, company) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: `productsModels?indexID=${indexID}&brand=${brand}&company=${company}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			})
			dispatch(getModelsSuccess(respuesta.data.response));
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

const actionProductsError = () => ({
	type: ACTION_PRODUCTS_ERROR,
});

const actionProductsStart = () => ({
	type: ACTION_PRODUCTS_START,
});

const getProductsSuccess = (payload) => ({
	type: GET_PRODUCTS_SUCCESS,
	payload: payload,
});

const getFiltersSuccess = (payload) => ({
	type: GET_FILTERS_SUCCESS,
	payload: payload,
});

const getBrandsSuccess = (payload) => ({
	type: GET_BRANDS_SUCCESS,
	payload: payload,
});

const getCompaniesSuccess = (payload) => ({
	type: GET_COMPANIES_SUCCESS,
	payload: payload,
});

const getModelsSuccess = (payload) => ({
	type: GET_MODELS_SUCCESS,
	payload: payload,
});