import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getProducts, getProductsBrands, getProductsCompanies, getProductsModels } from "../../../redux/actions/productos/productsAction"

import FiltroTarjeta from "./FiltroTarjeta";

import { Accordion, NativeSelect, Switch, NumberInput } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';

import "./filtrosItems.css";

export default function Filters({ productIndex }) {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const currency = useSelector((state) => state.currency.data);
	const productsReducer = useSelector((state) => state.products);

	const [minPrice, setMinPrice] = useState(searchParams.get('minPrice') !== null ? parseFloat(searchParams.get('minPrice')) : null);
	const [maxPrice, setMaxPrice] = useState(searchParams.get('maxPrice') !== null ? parseFloat(searchParams.get('maxPrice')) : null);

	const [minKms, setMinKms] = useState(searchParams.get('minKms') !== null ? parseFloat(searchParams.get('minKms')) : null);
	const [maxKms, setMaxKms] = useState(searchParams.get('maxKms') !== null ? parseFloat(searchParams.get('maxKms')) : null);

	const [brand, setBrand] = useState(searchParams.get('brand') !== null ? searchParams.get('brand') : null);
	const [company, setCompany] = useState(searchParams.get('company') !== null ? searchParams.get('company') : null);
	const [model, setModel] = useState(searchParams.get('model') !== null ? searchParams.get('model') : null);

	const [orderByValue, setOrderByValue] = useState(searchParams.get('orderByValue') !== null ? parseInt(searchParams.get('orderByValue')) : null);
	const [orderByField, setOrderByField] = useState(searchParams.get('orderByField') !== null ? searchParams.get('orderByField') : null);

	const [totalFiltros, setTotalFiltros] = useState([]);

	const [startDate, setStartDate] = useState(searchParams.get('minYear') !== null ? new Date(parseInt(searchParams.get('minYear')), 0, 1) : null);
	const [finishDate, setFinishDate] = useState(searchParams.get('maxYear') !== null ? new Date(parseInt(searchParams.get('maxYear')), 0, 1) : null);

	useEffect(() => {
		if (productsReducer.filters?.length > 0) {
			setTotalFiltros(
				productsReducer.filters.map((filtroItemMap) => {
					return {
						...filtroItemMap,
						labels: filtroItemMap.labels.map((label) => {
							return { ...label, check: false };
						}),
					};
				})
			);
		} else {
			setTotalFiltros([]);
		}
	}, [productsReducer.filters]);

	useEffect(() => {
		if (productsReducer.filters?.length > 0) {
			setTotalFiltros(
				productsReducer.filters.map((filtroItemMap) => {
					return {
						...filtroItemMap,
						labels: filtroItemMap.labels.map((label) => {
							return { ...label, check: productsReducer.filterSearch.includes(label.value) };
						}),
					};
				})
			);
		} else {
			setTotalFiltros([]);
		}
	}, [productsReducer.filterSearch, searchParams]);

	const toggleIsUpdated = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, isUpdated: !productsReducer.isUpdated, page: 1 }));
		searchParams.set("isUpdated", !productsReducer.isUpdated);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const toggleHasPrice = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, hasPrice: !productsReducer.hasPrice, page: 1 }));
		searchParams.set("hasPrice", !productsReducer.hasPrice);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const cambiarFiltros = (filtroNuevo) => {
		setTotalFiltros(
			totalFiltros.map((filtroMap) => {
				if (filtroMap._id === filtroNuevo._id) {
					return filtroNuevo;
				}
				return filtroMap;
			})
		);

		let filterSearch = productsReducer.filterSearch ? productsReducer.filterSearch : []
		filtroNuevo.labels.map(label => {
			if (label.check) {
				if (!filterSearch.includes(label.value)) {
					filterSearch.push(label.value);
				}
			} else {
				filterSearch = filterSearch.filter(item => item !== label.value);
			}
		})

		dispatch(getProducts(productIndex, { ...productsReducer, filterSearch: filterSearch, page: 1 }));

		filterSearch = filterSearch.map(item => item.replace(" ", "_"));
		searchParams.set("filterSearch", filterSearch.join(" "));
		setSearchParams(searchParams);
	};

	const handlBrandChange = (value) => {
		if (value !== "Todas las marcas") {
			dispatch(getProducts(productIndex, { ...productsReducer, brand: value, model: null, page: 1 }));
			dispatch(getProductsCompanies(productIndex, value))
			dispatch(getProductsModels(productIndex, value, searchParams.get('company')))
			setBrand(value)
			searchParams.set("brand", value);
		}
		else {
			dispatch(getProducts(productIndex, { ...productsReducer, brand: null, model: null, page: 1 }))
			dispatch(getProductsCompanies(productIndex, undefined))
			setBrand(null)
			searchParams.delete("brand");
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCompanyChange = (value) => {
		if (value !== "Todas las empresas") {
			dispatch(getProducts(productIndex, { ...productsReducer, company: value, model: null, page: 1 }));
			searchParams.set("company", value);
			dispatch(getProductsBrands(productIndex, value))
			dispatch(getProductsModels(productIndex, searchParams.get('brand'), value))
			setCompany(value)
		}
		else {
			dispatch(getProducts(productIndex, { ...productsReducer, company: null, model: null, page: 1 }));
			searchParams.delete("company");
			dispatch(getProductsBrands(productIndex, undefined))
			setCompany(null)
		}
		setModel(undefined)
		searchParams.delete("model");
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCurrencyChange = (value) => {
		dispatch(getProducts(productIndex, { ...productsReducer, currency: value }));
	}

	const handleMinYearChange = (value) => {
		if (value) {
			const variable = value.getFullYear()
			setStartDate(value)
			dispatch(getProducts(productIndex, { ...productsReducer, minYear: variable, page: 1 }))
			searchParams.set("minYear", variable);
			searchParams.set("page", 1);
		}
		else {
			setStartDate(value)
			dispatch(getProducts(productIndex, { ...productsReducer, minYear: 0, page: 1 }));
			searchParams.delete("minYear");
			searchParams.set("page", 1);
		}
		setSearchParams(searchParams);
	}

	const handleMaxYearChange = (value) => {
		if (value) {
			let maxVariable = value.getFullYear()
			setFinishDate(value)
			dispatch(getProducts(productIndex, { ...productsReducer, maxYear: maxVariable, page: 1 }))
			searchParams.set("maxYear", maxVariable);
			searchParams.set("page", 1);
		}
		else {
			setFinishDate(value)
			dispatch(getProducts(productIndex, { ...productsReducer, maxYear: 3000, page: 1 }));
			searchParams.delete("maxYear");
			searchParams.set("page", 1);
		}
		setSearchParams(searchParams);
	}

	const handleChangeMinPrice = (value) => {
		setMinPrice(value)
		dispatch(getProducts(productIndex, { ...productsReducer, minPrice: value, page: 1 }));
		searchParams.set("minPrice", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMaxPrice = (value) => {
		setMaxPrice(value)
		dispatch(getProducts(productIndex, { ...productsReducer, maxPrice: value, page: 1 }));
		searchParams.set("maxPrice", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMinKms = (value) => {
		setMinKms(value)
		dispatch(getProducts(productIndex, { ...productsReducer, minKms: value, page: 1 }));
		searchParams.set("minKms", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMaxKms = (value) => {
		setMaxKms(value)
		dispatch(getProducts(productIndex, { ...productsReducer, maxKms: value, page: 1 }));
		searchParams.set("maxKms", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlModelChange = (value) => {
		if (value !== "Todos los modelos") {
			dispatch(getProducts(productIndex, { ...productsReducer, model: value, page: 1 }));
			setModel(value)
			searchParams.set("model", value);
		}
		else {
			dispatch(getProducts(productIndex, { ...productsReducer, model: null, page: 1 }))
			setModel(null)
			searchParams.delete("model");
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleOrderChange = (value) => {
		setOrderByField(value.field)
		setOrderByValue(value.value)
		dispatch(getProducts(productIndex, { ...productsReducer, orderByField: value.field, orderByValue: value.value }));
		searchParams.set("orderByField", value.field);
		searchParams.set("orderByValue", value.value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleResetFilters = () => {
		setBrand(null)
		setModel(null)

		setStartDate(null)
		setFinishDate(null)

		setOrderByField("normalizedPrice")
		setOrderByValue(1)

		setMinPrice("")
		setMaxPrice("")

		setMinKms("")
		setMaxKms("")

		setCompany(null)

		searchParams.delete("brand");
		searchParams.delete("company");
		searchParams.delete("maxKms");
		searchParams.delete("maxPrice");
		searchParams.delete("maxYear");
		searchParams.delete("minKms");
		searchParams.delete("minPrice");
		searchParams.delete("minYear");
		searchParams.delete("model");
		searchParams.delete("orderByField");
		searchParams.delete("orderByValue");
		searchParams.set("page", 1);
		setSearchParams(searchParams);

		dispatch(getProducts(productIndex,
			{
				...productsReducer,
				brand: null,
				company: null,
				maxKms: null,
				maxPrice: null,
				maxYear: null,
				minKms: null,
				minPrice: null,
				minYear: null,
				model: null,
				models: [],
				orderByField: "normalizedPrice",
				orderByValue: 1,
			}));

		dispatch(getProductsBrands(productIndex, null))
		dispatch(getProductsCompanies(productIndex, null))
	}

	return (
		<Accordion variant="contained" className="filters-container" defaultValue="filters">
			<Accordion.Item key={1} value="filters">
				<Accordion.Control>FILTROS</Accordion.Control>
				<Accordion.Panel>

					<NativeSelect
						label="Ordenar por"
						size="xs"
						onChange={(e) => { handleOrderChange(JSON.parse(e.target.value)) }}
					>
						<option selected={orderByValue === 1 && orderByField === "normalizedPrice"} value={JSON.stringify({ field: "normalizedPrice", value: 1 })} key={"priceUpward"}>Precio ascendente</option>
						<option selected={orderByValue === -1 && orderByField === "normalizedPrice"} value={JSON.stringify({ field: "normalizedPrice", value: -1 })} key={"priceDownward"}>Precio descendente</option>
						<option selected={orderByValue === 1 && orderByField === "info.year"} value={JSON.stringify({ field: "info.year", value: 1 })} key={"yearUpward"}>Antiguedad ascendente</option>
						<option selected={orderByValue === -1 && orderByField === "info.year"} value={JSON.stringify({ field: "info.year", value: -1 })} key={"yearDownward"}>Antiguedad descendente</option>
						<option selected={orderByValue === 1 && orderByField === "info.kms"} value={JSON.stringify({ field: "info.kms", value: 1 })} key={"kmsUpward"}>Kilometraje ascendente</option>
						<option selected={orderByValue === -1 && orderByField === "info.kms"} value={JSON.stringify({ field: "info.kms", value: -1 })} key={"kmsDownward"}>Kilometraje descendente</option>
					</NativeSelect>

					<NativeSelect
						label="Empresa"
						size="xs"
						onChange={(e) => { handleCompanyChange(e.target.value) }}
						value={company}
					>
						<option selected={!company} value={null} key={"allCompanies"}>Todas las empresas</option>
						{productsReducer.companies.map((item) => {
							return (<option value={item._id} key={item._id} style={{ textTransform: "capitalize" }}>{item._id} ({item.count})</option>);
						})}
					</NativeSelect>

					<NativeSelect
						label="Marca"
						size="xs"
						onChange={(e) => { handlBrandChange(e.target.value) }}
						value={brand}
					>
						<option selected={!brand} value={undefined} key={"allBrands"}>Todas las marcas</option>
						{productsReducer.brands.map((brand) => {
							return (<option value={brand._id} key={brand._id} >{brand._id} ({brand.count})</option>)
						})}
					</NativeSelect>

					{brand && <NativeSelect
						label="Modelo"
						size="xs"
						onChange={(e) => { handlModelChange(e.target.value) }}
						value={model}
					>
						<option selected={!model} value={undefined} key={"allModels"}>Todos los modelos</option>
						{productsReducer.models.map((brand) => {
							return (<option value={brand._id} key={brand._id} >{brand._id} ({brand.count})</option>)
						})}
					</NativeSelect>}

					<div className="filters-dates">
						<h4>Año</h4>
						<div>
							<YearPickerInput
								clearable
								value={startDate}
								hideOutsideDates
								onChange={(e) => handleMinYearChange(e)}
								placeholder="Año minimo"
								maxDate={finishDate ? finishDate : new Date()}
								size="xs"
							/>
							<YearPickerInput
								clearable
								value={finishDate}
								hideOutsideDates
								onChange={(e) => { handleMaxYearChange(e) }}
								placeholder="Año maximo"
								minDate={startDate}
								maxDate={new Date()}
								size="xs"
							/>
						</div>
					</div>




					{/* <NativeSelect
						label="Comparativa"
						size="xs"
						onChange={(e) => { handleCurrencyChange(e.target.value) }}
						value={productsReducer.currency}
					>
						<option value={currency.name} key={currency.name}>{currency.name}</option>
						{Object.keys(currency.convertTo).map((item) => {
							return (<option value={item} key={item}>{item}</option>);
						})} 
					</NativeSelect> */}



					<div className="filters-numbers">
						<h4>Precio</h4>
						<div>
							<NumberInput
								min={0}
								max={maxPrice || undefined}
								placeholder="Minimo"
								prefix="$"
								size="xs"
								step={productsReducer.currency === "ARS" ? 1000000 : 1000}
								thousandSeparator=","
								value={minPrice}
								onChange={handleChangeMinPrice}
							/>
							<NumberInput
								min={minPrice || 0}
								placeholder="Maximo"
								prefix="$"
								size="xs"
								step={productsReducer.currency === "ARS" ? 1000000 : 1000}
								thousandSeparator=","
								value={maxPrice}
								onChange={handleChangeMaxPrice}
							/>
						</div>
					</div>

					<div className="filters-numbers">
						<h4>Kilometraje</h4>
						<div>
							<NumberInput
								min={0}
								max={maxKms || undefined}
								placeholder="Minimo"
								suffix="kms"
								size="xs"
								step={1000}
								thousandSeparator=","
								value={minKms}
								allowDecimal={false}
								onChange={handleChangeMinKms}
							/>
							<NumberInput
								min={minKms || 0}
								placeholder="Maximo"
								suffix="kms"
								size="xs"
								step={1000}
								thousandSeparator=","
								value={maxKms}
								allowDecimal={false}
								onChange={handleChangeMaxKms}
							/>
						</div>
					</div>

					<section>
						<h4>Tipo de moneda</h4>
						{currency && <NativeSelect
							size="xs"
							onChange={(e) => { handleCurrencyChange(e.target.value) }}
							value={productsReducer.currency}
						>
							<option value={currency.name} key={currency.name}>{currency.name}</option>
							{Object.keys(currency.convertTo).map((item) => {
								return (<option value={item} key={item}>{item}</option>);
							})}
						</NativeSelect>}
					</section>

					<Switch
						checked={productsReducer.isUpdated}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos actualizados"
						size="md"
						onClick={toggleIsUpdated}
					/>

					<Switch
						checked={productsReducer.hasPrice}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos con precio"
						size="md"
						onClick={toggleHasPrice}
					/>


					<h6 onClick={handleResetFilters}>Resetear filtros</h6>

					{totalFiltros.length > 0 && (
						<div>
							{totalFiltros.map((filtro, i) => {
								return <FiltroTarjeta filtro={filtro} key={i} cambiarFiltros={cambiarFiltros} />;
							})}
						</div>
					)}
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
}
