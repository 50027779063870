import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import noImage from "../../../assets/svg/system-images/producto-sin-imagen.svg";

import "./productCard.css";

export default function ProductCard({ item }) {
	const currency = useSelector((state) => state.products.currency);

	const [producto, setProducto] = useState(item);

	useEffect(() => {
		let precioProducto = parseFloat(producto.normalizedPrice);

		const productoDesactualizado = Date.parse(new Date()) - Date.parse(producto.timestamp) - 24 * 60 * 60 * 1000 > 0;

		setProducto({ ...producto, productoDesactualizado, precioProducto });
	}, [item]);

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1));
	};

	const formatNumber = (valor) => {
		return new Intl.NumberFormat("de-DE").format((valor * 1));
	};

	return (
		<div>
			<section className="productCard-timestamp" style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-red)", borderBottom: "0px" } : {}}>
				{producto.productoDesactualizado > 0 ?
					<span style={{ color: "var(--color-red)" }}>Producto desactualizado. Ultima actualización {producto.timestamp.split("T")[0]}</span>
					: <span>Extracción de articulo: {producto.timestamp.split("T")[1].split(':').slice(0, 2).join(':')}hs</span>
				}
			</section>
			<Link
				to={`/Producto?id=${item._id}`}
				className="productCard-container"
				key={item._id}
				style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-red)" } : {}}
			>

				<div>
					<div>
						<img
							alt="img. prod."
							src={
								Array.isArray(producto.info.images) && producto.info.images[0]
									? producto.info.images[0]
									: noImage
							}
						/>
						<img
							alt="img. prod."
							src={
								Array.isArray(producto.info.images) && producto.info.images[0]
									? producto.info.images[0]
									: noImage
							}
						/>
					</div>
					<section>
						<div>
							<h3>{producto.info.brand} - {producto.info.model}</h3>
							<h4>{producto.info.version}</h4>
							<h5>{producto.company.name}</h5>
						</div>
					</section>
					<div>
						{producto.normalizedPrice * 1 === 0 ?
							<span>
								Consultar precio
							</span>
							:
							<span>
								{currency}{" "}
								{formatearNumero(producto.normalizedPrice * 1)}
							</span>
						}
					</div>
					<section>
						<h5>{producto.info.year}</h5>
						<h5>{formatNumber(producto.info.kms)} kms</h5>
						<h5>--- %</h5>	{/*ToChange*/}
					</section>
				</div>
			</Link>
		</div>
	);
}
