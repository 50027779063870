import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import universlaCookie from "universal-cookie";

import { Modal, Box } from "@mui/material";

import { logOutUser } from "../../../redux/actions/utilities/authAction";

import accountsIcon from '../../../assets/svg/menu-icons/cuenta.svg'
import homeIcon from '../../../assets/svg/menu-icons/inicio-icono.svg'
import excelLoadIcon from '../../../assets/svg/menu-icons/excel-load.svg'
import filtersIcon from '../../../assets/svg/menu-icons/filtroicono.svg'
import marketPlayetsIcon from '../../../assets/svg/menu-icons/proveedoresicono.svg'
import closeSessionIcon from "../../../assets/svg/menu-icons/cerrar-sesion.svg";
import closeMenuIcon from "../../../assets/svg/menu-icons/flecha-triangular-white.svg";

import './navbarMenu.css';

const cookie = new universlaCookie();

export function NavbarMenu({ handleCloseNavbarMenu }) {
    const navegation = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    const [active, setActive] = useState(location.pathname);

    const data = user.role && (user.role.access === "superUser" ? [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon },
        { link: '/CargaPlanillas', label: 'Carga de planillas', icon: excelLoadIcon },
        { link: '/Colaboradores', label: 'Colaboradores', icon: accountsIcon },
        { link: '/ActoresDeMercado', label: 'Actores de mercado', icon: marketPlayetsIcon },
        { link: '/Categorizacion', label: 'Categorización', icon: filtersIcon },
    ] : user.role.access === "admin" ? [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon },
        { link: '/CargaPlanillas', label: 'Carga de planillas', icon: excelLoadIcon },
        { link: '/Colaboradores', label: 'Colaboradores', icon: accountsIcon },
    ] : [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon }
    ]);

    const handleLogOut = (event) => {
        event.preventDefault();
        cookie.remove("token");
        localStorage.removeItem("user");
        localStorage.setItem("LogInStatus", false);
        dispatch(logOutUser());
        navegation("/");
    }

    const handleClickAwayClose = (event, reason) => {
        if (event.clientX < 300) {
            return;
        }
        handleCloseNavbarMenu()
    };

    return (
        <Box >
            <Modal open={true}
                style={{ backdropFilter: "blur(4px)" }}
                onClick={handleClickAwayClose}
            >
                <nav className="navbarMenu-container" >
                    <div className="navbarMenu-navbarMain">
                        <div className="navbarMenu-header" >
                            <img alt='close-menu-icon' src={closeMenuIcon} onClick={handleCloseNavbarMenu} />
                            <h6 onClick={handleCloseNavbarMenu}>Cerrar menú</h6>
                        </div>
                        {data && data.map((item) => (
                            <a
                                href='/'
                                className="navbarMenu-link"
                                data-active={item.link === active || undefined}
                                to={item.link}
                                key={item.label}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setActive(item.label);
                                    navegation(item.link)
                                    handleCloseNavbarMenu()
                                }}
                            >
                                <img alt='item-icon' src={item.icon} />
                                <span>{item.label}</span>
                            </a>
                        ))}
                    </div>
                    <section>
                        <span>{user.info?.lastName[0]}{user.info?.name[0]}</span>
                        <h4>{user.contact?.email}</h4>
                    </section>
                    <div className="navbarMenu-footer" >
                        <a href="/" className="navbarMenu-link" onClick={(event) => handleLogOut(event)}>
                            <img alt='close-session-icon' src={closeSessionIcon} />
                            <span>Cerrar sesión</span>
                        </a>
                    </div>
                </nav>
            </Modal>
        </Box >
    );
}